/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/*
Note: any .css or .scss files included in the 'styles' directory
will be correctly compiled during `gulp serve` and `gulp`
 */

html, body {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 766px) {
  html, body {
    background-color: #fac2ff;
    background-image: linear-gradient(121deg, #fac2ff 25%, #2BFFE8 89%);
  }
}
@media screen and (min-width: 767px) {
  html, body {
    background-color: #fac2ff;
    background-image: linear-gradient(152deg, #fac2ff 29%, #2bffe9 89%);
  }
}

::-moz-selection { background: rgba(255, 255, 0, 0.65); color: #0078ff; }
::selection  { background: rgba(255, 255, 0, 0.65); color: #0078ff; }

h1 {
  font-size: 10em;
  margin: 5px;
  color: #3a3a3a;
}

.top {
  max-width: 950px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.descript {
  /*align-self: flex-start;*/
  /*flex-grow:1;*/

  padding-left: 5px;
  padding-right: 5px;
  margin-left: 22px;
  margin-right: 22px;
}

/*.tweet {*/
  /*!*align-self: flex-start;*!*/
  /*flex-grow:1;*/
/*}*/

.descript p.thanks {
  font-size: 1.1em;
  color: #e7eff6;
  background-color: #9f5eff;

  text-shadow: #171717 0px 1px 1px;
  text-align: center;
}

.descript p.no-thanks {
  font-size: 0.75em;
  color: #3a3a3a;
  text-align: center;
  opacity: 0.45;
}

.www {
  /*align-self: flex-start;*/
}

.spin {
  /*font-size: 1.1em;*/
  /*color: #667c80;*/
  /*background-color: #9f5eff;*/
  /*padding-left: 5px;*/
  /*padding-right: 5px;*/
  /*margin-left: 5px;*/
  /*margin-right: 5px;*/
  /*text-shadow: #171717 0px 1px 1px;*/
  /*margin: auto;*/
  /*text-align: center;*/
  animation:spin 4s linear infinite;
}

.no-spin {
  margin: auto;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg);} }


.my-content {
  max-width: 750px;
  margin: 0 auto;
  text-align: left;
  padding: 25px 25px;

}

.content {
  background-color: #f6fdff;
  margin-bottom: 0;

}

.footer {
  /*display: flex;*/
  background-color: #c1eef4;
  color: #202a31;
  /*text-align: center;*/
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*align-content: flex-end;*/
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}

.footer div {
  /*color: #404040;*/
  /*text-decoration: solid;*/
  /*padding-top: 1em;*/
  /*margin-bottom: auto;*/
  /*align-content: flex-end;*/
}

.tweet a {
  color: #3a3a3a;
}

.stuff {
  flex-grow: 1;
}

.my-content h2 span {
  color: white;
  background-color: #252525;
  padding: 5px 0px 5px 8px;
  letter-spacing: 0.2em;
  font-size: 135%;
  text-shadow: #4d4d4d 0px 2px 2px;
}

.nospin {
  flex-grow: 1;
}


body {
  display: flex;
  flex-direction: column;
}
.footer {
  flex: 1 0 auto;
}

.welcome {
  text-align: center;
  text-decoration: underline;
  background-color: rgba(255, 237, 38, 0.64);
}

.cap {
  text-transform: uppercase;

}

a {
  color: #d438c4;
}

a:hover {
  color: #9f5eff;
}

@media screen and (max-width: 948px) {
  .title h1 {
    /*font-size: 100%;*/
    font-size: 5em;
  }
}

.links {
  color: blue !important;
  text-decoration: none;
}

a.links:hover {
  color: blue !important;
  text-decoration: underline;
}

.hello {
  cursor: help;
}

hr.blue {
  height: 2px;
  color: #0000FF;
  background-color: #0000FF;
  border: none;
}

.endspin {
  width: 100%;
  text-align: center;
}

.box {
  width: 50px;
  height: 50px;
  text-align: center;
  display: inline-block;
  opacity: 0.5;
  font-size: 175%;
}

.hl {
  padding: 0px 3px;
  background-color: rgba(255, 237, 38, 0.25);
}

.hl-o {
  padding: 0px 3px;
  background-color: rgba(255, 127, 60, 0.26);
}

.hl-b {
  padding: 0px 3px;
  background-color: rgba(65, 166, 255, 0.25);
}

.photo {
  border: 2px solid #0000FF;
  width: 100px;
  height: 100px;
  margin: 4px;
}



.wrapper {
  display: grid;
  grid-template-columns: 4fr 1fr;
}

@media screen and (max-width: 766px) {
  #twitter-widget-0 {
    height: 260px !important;
  }
}
